import React, { useEffect, useContext, lazy, Suspense } from "react";
import { graphql, Link } from "gatsby";
import { MDXRenderer } from "gatsby-plugin-mdx";
import "katex/dist/katex.min.css";

import Layout from "components/layout";
import NextPrevButtons from "components/NextPrevButtons";
import { lessonDispatchContext } from "context/lessonsContext";
import LazyExerciseSolution from "components/LazyExerciseSolution";
import ExerciseTitle from "components/ExerciseTitle";

const ExoTemplate = ({
  banner,
  next,
  previous,
  content,
  printInfo,
  location,
}) => {
  return (
    <>
      <header className={`banner banner-${banner}`}>
        <div className="content">
          <h2 style={{ color: "white" }}>
            دروس خصوصية فردية مع الأستاذ بن أحمد
          </h2>
          <p className="description">
            لكل مستويات الطور الثانوي في مادة العلوم الفيزيائية
          </p>
          <Link className="button primary" to="/دروس-خصوصية">
            اتصل الآن
          </Link>
        </div>
      </header>

      <NextPrevButtons
        next={next}
        prev={previous}
        MiddleButton={() => <div></div>}
      />

      <ExerciseTitle
        title="تمرين"
        rank={printInfo.rank}
        difficulty={printInfo.difficulty}
      />
      <MDXRenderer printInfo={printInfo}>{content}</MDXRenderer>
      <LazyExerciseSolution printInfo={printInfo} location={location} />

      <NextPrevButtons
        next={next}
        prev={previous}
        MiddleButton={() => <div></div>}
      />
    </>
  );
};

const Exercise = ({ data, pageContext, location }) => {
  const { title } = pageContext;
  const setLessonDetails = useContext(lessonDispatchContext);

  const getLessonInfo = (data, title) => {
    const {
      node: {
        id,
        childMdx: {
          frontmatter,
          body,
          excerpt,
          fields: { slug },
        },
      },
      next,
      previous,
    } = data.allFile.edges.filter(
      ({ node }) => node.childMdx.frontmatter.title === title
    )[0];

    return {
      next: next && {
        title: next.childMdx.frontmatter.title,
        slug: next.childMdx.fields.slug,
      },
      previous: previous && {
        title: previous.childMdx.frontmatter.title,
        slug: previous.childMdx.fields.slug,
      },
      printInfo: {
        slug,
        id,
        title: frontmatter.title,
        lessonType: frontmatter.lessonType,
        level: frontmatter.level,
        unite: frontmatter.unite,
        domain: frontmatter.domain,
        division: frontmatter.division,
        authorId: frontmatter.author,
        exoId: frontmatter.exoId,
        ads: true,
        rank: frontmatter.rank,
        difficulty: frontmatter.difficulty,
      },
      exo: {
        banner: frontmatter.banner,
        thumbnail: frontmatter.thumbnail.childImageSharp.fluid,
        body,
        description: frontmatter.description || excerpt,
      },
    };
  };
  const { exo, next, previous, printInfo } = getLessonInfo(data, title);
  const { banner, thumbnail, body, description } = exo;
  useEffect(() => {
    setLessonDetails(state => ({ ...state, lessonInfo: printInfo }));
  }, [printInfo]);

  return (
    <Layout
      title={title}
      description={description}
      classTitle="main"
      classSection="lesson"
      image={thumbnail}
      location={location}
    >
      <ExoTemplate
        banner={banner}
        next={next}
        previous={previous}
        content={body}
        printInfo={printInfo}
        location={location}
      />
    </Layout>
  );
};

export default Exercise;
export const query = graphql`
  query($level: String!, $division: String!) {
    allFile(
      filter: {
        sourceInstanceName: { eq: "exercises" }
        childMdx: {
          frontmatter: { level: { eq: $level }, division: { eq: $division } }
        }
      }
      sort: { fields: childMdx___frontmatter___rank }
    ) {
      edges {
        node {
          id
          childMdx {
            fields {
              slug
            }
            frontmatter {
              title
              domain
              lessonType
              unite
              division
              level
              rank
              author
              exoId
              description
              difficulty
              banner
              thumbnail {
                childImageSharp {
                  fluid(maxWidth: 75, quality: 70) {
                    ...GatsbyImageSharpFluid_withWebp
                    originalName
                  }
                }
              }
            }
            body
            excerpt
          }
        }
        next {
          childMdx {
            fields {
              slug
            }
            frontmatter {
              title
            }
          }
        }
        previous {
          childMdx {
            fields {
              slug
            }
            frontmatter {
              title
            }
          }
        }
      }
    }
  }
`;
